<template>
  <div class="d-flex justify-content-center mt-4 mb-4 p-3">
    <div class="card cardEncuesta mb-3" style="width: 28rem">
      <div class="card-header d-flex justify-content-between">
        <p class="titulo1">Calificación</p>
        <a
          href="javascript:void(0)"
          @click="cerrar"
          style="text-decoration: none !important; color: black"
          class="titulo1"
          >Cerrar</a
        >
      </div>
      <div class="card-body">
        <section v-if="section0">
          <p class="text-start titulo1">¿Su requerimiento fue atendido?</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="si"
              v-model="atendioRequerimiento"
              value="SI"
            />
            <label class="form-check-label marginLabel" for="si"> Si </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="no"
              v-model="atendioRequerimiento"
              value="NO"
            />
            <label class="form-check-label marginLabel" for="no"> No </label>
          </div>
        </section>

        <section v-if="section0">
          <p class="text-start titulo1">
            ¿El técnico le explicó cómo debe manejar adecuadamente el equipo de
            frío?
          </p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              v-model="explicoManejo"
              value="SI"
            />
            <label class="form-check-label marginLabel" for="si"> Si </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              v-model="explicoManejo"
              value="NO"
            />
            <label class="form-check-label marginLabel" for="no"> No </label>
          </div>
        </section>

        <section v-if="section0">
          <p class="text-start titulo1">
            ¿El técnico le explicó qué tipo de conexiones eléctricas debería
            tener en su local?
          </p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              v-model="explicoConexion"
              value="SI"
            />
            <label class="form-check-label marginLabel" for="si"> Si </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              v-model="explicoConexion"
              value="NO"
            />
            <label class="form-check-label marginLabel" for="no"> No </label>
          </div>
        </section>

        <section class="mt-3" v-if="section0">
          <p class="text-start titulo1">
            ¿Como calificaría el servicio brindado por el técnico?
          </p>
          <div class="d-flex align-items-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img"
                src="../../assets/5.png"
                width="35"
                height="35"
              />
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="nivelSatisfaccion"
                id="muySatisfecho"
                value="MUY SATISFECHO"
                @click="seleccionarRespuesta('MUYSATISFECHO')"
              />
              <label class="form-check-label marginLabel" for="muySatisfecho">
                Muy satisfecho
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img"
                src="../../assets/4.png"
                width="35"
                height="35"
              />
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="nivelSatisfaccion"
                id="satisfecho"
                value="SATISFECHO"
                @click="seleccionarRespuesta('SATISFECHO')"
              />
              <label class="form-check-label marginLabel" for="satisfecho">
                Satisfecho
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img"
                src="../../assets/3.png"
                width="35"
                height="35"
              />
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="nivelSatisfaccion"
                id="neutral"
                value="NEUTRAL"
                @click="seleccionarRespuesta('NEUTRAL')"
              />
              <label class="form-check-label marginLabel" for="neutral">
                Neutral
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img"
                src="../../assets/2.png"
                width="35"
                height="35"
              />
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="nivelSatisfaccion"
                id="insatisfecho"
                value="INSATISFECHO"
                @click="seleccionarRespuesta('INSATISFECHO')"
              />
              <label class="form-check-label marginLabel" for="insatisfecho">
                Insatisfecho
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center bd-highlight">
            <div class="p-2 bd-highlight">
              <img
                class="img"
                src="../../assets/1.png"
                width="35"
                height="35"
              />
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="nivelSatisfaccion"
                id="muyInsatisfecho"
                value="MUY INSATISFECHO"
                @click="seleccionarRespuesta('MUYINSATISFECHO')"
              />
              <label class="form-check-label marginLabel" for="muyInsatisfecho">
                Muy insatisfecho
              </label>
            </div>
          </div>
        </section>
        <section class="mt-3" v-show="section2" id="section2">
          <p class="text-start titulo1">
            ¿Qué consideras que deberíamos mejorar en el servicio? (Recuerda que
            puedes escoger una o varias opciones)
          </p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value="No me llamaron antes de atender mi requerimiento"
              id="respuesta1"
              @click="agregarEliminar($event)"
            />
            <label
              class="form-check-label text-start marginLabel"
              for="respuesta1"
              >No me llamaron antes de atender mi requerimiento
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value="El técnico no fue educado"
              id="respuesta2"
              @click="agregarEliminar($event)"
            />
            <label
              class="form-check-label text-start marginLabel"
              for="respuesta2"
              >El técnico no fue educado</label
            >
          </div>
          <div class="form-check" v-show="this.tipoSolicitud === 'MANTENIMIENTO'">
            <input
              class="form-check-input"
              type="checkbox"
              value="El técnico no me explicó lo que estaba pasando como mi equipo de frío"
              id="respuesta3"
              @click="agregarEliminar($event)"
            />
            <label
              class="form-check-label text-start marginLabel"
              for="respuesta3"
            >
              El técnico no me explicó lo que estaba pasando como mi equipo de
              frío
            </label>
          </div>
          <div class="form-check" v-show="this.tipoSolicitud === 'MANTENIMIENTO'">
            <input
              class="form-check-input"
              type="checkbox"
              value="El técnico no resolvió el problema del equipo de frío"
              id="respuesta4"
              @click="agregarEliminar($event)"
            />
            <label
              class="form-check-label text-start marginLabel"
              for="respuesta4"
            >
              El técnico no resolvió el problema del equipo de frío
            </label>
          </div>
        </section>
        <!-- <section class="mt-3" v-show="section3">
          <p class="text-start titulo1">
            ¿Qué es lo que consideras que debemos mejorar? (Recuerda
            que puedes escoger una o varios opciones)
          </p>
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value="Mi vendedor no me visita con frecuencia"
                id="respuesta4"
                @click="agregarEliminar($event)"
            />
            <label
                class="form-check-label text-start marginLabel"
                for="respuesta4"
            >
              Mi vendedor no me visita con frecuencia
            </label>
          </div>
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value="El técnico no fue educado"
                id="respuesta5"
                @click="agregarEliminar($event)"
            />
            <label
                class="form-check-label text-start marginLabel"
                for="respuesta5"
            >
            El técnico no fue educado
            </label>
          </div>
          <div class="form-check" v-show="seccion6 === 'MANTENIMIENTO'">
            <input
                class="form-check-input"
                type="checkbox"
                value="El técnico no me explicó lo que estaba pasando como mi equipo de frío"
                id="respuesta6"
                @click="agregarEliminar($event)"
            />
            <label
                class="form-check-label text-start marginLabel"
                for="respuesta6"
            >
              El técnico no me explicó lo que estaba pasando como mi equipo de frío
            </label>
          </div>
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value="El técnico no resolvió el problema del equipo de frío."
                id="respuesta7"
                @click="agregarEliminar($event)"
            />
            <label
                class="form-check-label text-start marginLabel"
                for="respuesta7"
            >
              El técnico no resolvió el problema del equipo de frío.
            </label>
          </div>
        </section> -->
        <section class="mb-3 mt-3" v-show="section4">
          <textarea
            class="form-control"
            id="comentarios"
            rows="4"
            v-model="comentarios"
            placeholder="Déjanos tu comentario (Opcional)"
            maxlength="150"
          ></textarea>
        </section>
        <section v-if="section5">
          <p class="text-start">Muchas gracias por tu tiempo.</p>
          <p class="text-start">
            Como Cervecería Nacional estamos comprometidos en brindar un servicio de excelencia.
          </p>

        </section>
        <p v-if="!mensaje" class="text-danger text-center">{{ mensaje }}</p>
        <div class="d-grid gap-2 mt-3" v-if="divEnviar">
          <button class="btn1 btn-dark" type="button" @click="enviar">
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      respuesta: "",
      atendioRequerimiento: "",
      explicoManejo: "",
      explicoConexion: "",
      section0: true,
      section1: false,
      section2: false,
      section3: false,
      section4: false,
      section5: false,
      divEnviar: false,
      nivelSatisfaccion: "",
      respuestas: [],
      pregunta: "",
      codigoCliente: "",
      comentarios: "",
      mensaje: null,
      tipo: null,
    };
  },
  created() {
    if (!this.infoTecnico) {
      this.$store.commit("tecnico/setTecnicoInfo", null);
      this.$router.push("/");
    }
  },
  methods: {
    seleccionarRespuesta(respuesta) {
      this.mensaje = null;

      this.limpiarChecks();
      this.pregunta = "";
      this.comentarios = "";

      if (
        respuesta === "NEUTRAL" ||
        respuesta === "INSATISFECHO" ||
        respuesta === "MUYINSATISFECHO"
      ) {
        this.section2 = true;
        this.section1 = true;
        this.section3 = false;
        this.section4 = true;
        this.pregunta =
          "¿Qué es lo que consideras que debemos mejorar en el servicio?";
        this.respuestas = [];
        this.divEnviar = false;
        setTimeout(() => {
          this.desplazar();
        }, 200);

        return;
      }

      if (respuesta === "SATISFECHO" || respuesta === "MUYSATISFECHO") {
        this.section1 = true;
        this.section2 = false;
        this.section3 = false;
        this.section4 = false;
        this.respuestas = [];
        this.pregunta = "";
        this.divEnviar = true;
        return;
      }
    },
    desplazar() {
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    },
    cerrar() {
      this.$router.push("/");
    },
    limpiarChecks() {
      let checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    },
    agregarEliminar(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.respuestas.push({ respuesta: valor });
      } else {
        const filtrados = this.respuestas.filter(
          (item) => item.respuesta !== valor
        );
        this.respuestas = filtrados;
      }
      this.divEnviar = false;

      if (this.respuestas.length > 0) {
        this.divEnviar = true;
      }
    },
    async enviar() {
      this.mensaje = null;
      try {
        const data = {
          codigoCliente: this.infoTecnico?.codigoCliente,
          comentarios: this.comentarios,
          nivelSatisfaccion: this.nivelSatisfaccion,
          pregunta: this.pregunta,
          respuestas: this.respuestas,
          atendioRequerimiento: this.atendioRequerimiento,
          explicoManejo: this.explicoManejo,
          explicoConexion: this.explicoConexion,
          ticket: this.ticket,
          tipoRegistro: this.infoTecnico?.tipoRegistro
        };

        await this.$store.dispatch("tecnico/enviarEncuesta", data);

        this.section0 = false;
        this.section1 = false;
        this.section3 = false;
        this.section2 = false;
        this.section4 = false;
        this.divEnviar = false;
        this.section5 = true;
      } catch (error) {
        this.mensaje = "No se pudo guardar la encuesta";
      }
    },
  },
  computed: {
    infoTecnico() {
      return this.$store.getters["tecnico/getTecnicoInfo"];
    },
    tipoSolicitud(){
      return this.$store.getters["tecnico/getTecnicoInfo"]?.tipoSolicitud;
    },
    ticket(){
      return this.$store.getters["tecnico/getTicket"];
    },
  },
};
</script>
<style>
.marginLabel {
  margin-left: 1em;
}

.btn1 {
  height: 40px;
  width: 100%;
  border: none;
  background-color: black !important;
  color: white !important;
  font-size: 15px;
  border-radius: 5px;
}


.cardEncuesta {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.titulo1 {
  font-weight: bold;
}
</style>
