<template>
  <div>
    <div class="container mt-4 mb-4 p-3 d-flex justify-content-center">
      <div class="card-validar p-4">
        <div class="containerTitle text-center">
          <span class="name"
          >Técnico Asignado</span
          >

        </div>
        <div
            class="image d-flex flex-column justify-content-center align-items-center"
        >
          <div class="foto">
            <img
                class="profile"
                :src="`data:image/png;base64,${dataTecnico.foto}`"
                height="300"
            />
          </div>
        </div>
        <div>
          <div class="text">
            <div class="row">
              <div class="col">Nombres y apellidos:</div>
              <div class="col">
                {{ dataTecnico?.nombre }}
              </div>
            </div>

            <div class="row">
              <div class="col">Número de celular:</div>
              <div class="col">
                {{ dataTecnico?.celular }}
              </div>
            </div>

            <div class="row">
              <div class="col">Cédula:</div>
              <div class="col">
                {{ dataTecnico?.cedula }}
              </div>
            </div>

          </div>

        </div>
        <div class="mt-3" v-if="mostrarEncuesta">
          <button
              type="button"
              @click="abrirEncuesta"
              class="btn1 btn-dark btn-sm"
              style="height: 50px !important;"
          >
            Realizar encuesta sobre la visita del técnico
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataTecnico: {},
    };
  },
  methods: {
    async getDataTecnico() {
      this.dataTecnico = this.$store.getters["tecnico/getTecnicoInfo"];
      if (!this.dataTecnico) {
        this.$router.push("/");
      }
    },
    abrirEncuesta() {
      this.$router.push("/encuesta");
    },
  },
  mounted() {
    this.getDataTecnico();
  },
  computed: {
    mostrarEncuesta() {
      return this.$store.getters["tecnico/getTecnicoMostrarEncuesta"];
    },
  },
};
</script>
<style scoped>
.card-validar {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: white !important;
}

.image img {
  transition: all 0.5s;
}

.card:hover .image img {
  transform: scale(1.5);
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.text span {
  font-size: 12px;
  color: black;
}

.text {
  font-size: 14px;
  color: black;
}

.icons i {
  font-size: 19px;
}

.foto {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: black !important;
  padding: 2px;
  border-radius: 50%;
}

.header-image {
  width: 40px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center; /* Only if you want it vertically center-aligned as well */
  margin-top: 10px;
  margin-bottom: 10px;
}

.subtitle {
  font-weight: bold;
  font-size: 14px;
}

.profile {
  border-radius: 50%;
}

.containerTitle {
  background-color: #fdfe00;
  padding: 5px;
  border-radius: 5px;
}

.btn1 {
  height: 40px;
  width: 100%;
  border: none;
  background-color: black !important;
  color: white !important;
  font-size: 15px;
  border-radius: 5px;
}
</style>
