import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import DetalleTecnico from "../views/tecnico/DetalleTecnico";
import Encuesta from "../views/encuesta/Encuesta";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/detalle-tecnico',
    name: 'DetalleTecnico',
    component: DetalleTecnico
  }
  ,
  {
    path: '/encuesta',
    name: 'Encuesta',
    component: Encuesta
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
