<template>
  <div className="container mt-4 mb-4 p-3 d-flex justify-content-center">
    <div className="card-validar p-4">
      <div>
        <h6 className="title text-center">
          Técnico asignado
        </h6>
        <p style="color: black; font-size: 12px; margin-top: -20px">
          Para visulizar la información, debes ingresar el número de ticket
        </p>
        <br />
        <p style="color: black; font-size: 12px; margin-top: -20px">
          El número de ticket llegará al celular registrado mediante SMS
        </p>
        <form @submit.prevent="validar">
          <!--<div class="mb-3">
            <label for="codigo" class="form-label">Código de Cliente</label>
            <input type="text" v-model="formData.codigoCliente" class="form-control input-validacion" id="codigo"
                   required>
          </div>-->
          <div className="mb-3">
            <label htmlFor="identificacion" className="form-label"
              >Ingresa el número de ticket que fue enviado por SMS</label
            >
            <input
              type="text"
              v-model="formData.numeroTicket"
              className="form-control input-validacion"
              id="identificacion"
              required
            />
          </div>
          <div v-if="mostrarMensaje" class="mb-2 text-center">
            <small class="text-danger">{{ mensaje }}</small>
          </div>

          <div>
            <button type="submit" className="btn1 btn-dark " :disabled="isLoading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
              <span v-if="isLoading">  Procesando...</span>
              <span v-else>  LISTO</span>
            </button>
          </div>
        </form>

        <div className="d-flex justify-content-center">
          <img className="logo-inicial" src="../assets/logo-inicial.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      mostrarMensaje: false,
      formData: {},
      mensaje: "",
      isLoading: false
    };
  },
  methods: {
    async validar() {
      this.mostrarMensaje = false;
      this.isLoading = true;
      try {
        const resp = await this.$store.dispatch(
          "tecnico/consultarTecnicoInfo",
          this.formData
        );
        if (resp?.nombre) {
          this.$router.push("/detalle-tecnico");
        } else {
          this.mensaje = resp;
          this.mostrarMensaje = true;
        }
      } catch (error) {
        this.mensaje = "No se pudo verificar la información";
        this.mostrarMensaje = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style>
.card-validar {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: white !important;
  width: 400px;
}

.title {
  font-weight: bolder;
  margin-bottom: 25px;
  color: black;
}

.form-label {
  font-size: 12px;
  color: black;
}

.image img {
  transition: all 0.5s;
}

.card:hover .image img {
  transform: scale(1.5);
}

.btn1 {
  height: 40px;
  width: 100%;
  border: none;
  background-color: black !important;
  color: white !important;
  font-size: 15px;
  border-radius: 5px;
}

.text span {
  font-size: 13px;
  color: #545454;
  font-weight: 500;
}

.input-validacion {
  border-color: gray !important;
}

.input-validacion:hover {
  border: 1px solid black !important;
}

.logo-inicial {
  margin-top: 30px;
  width: 200px;
  text-align: center;
}
</style>
