import axios from "axios";

const state = {
  tecnicoInfo: null,
  mostrarMensaje: false,
  encuestaEnviada: false,
  ticket: null
};

const getters = {
  getTicket: (state) => state.ticket,
  getTecnicoInfo: (state) => state.tecnicoInfo,
  getTecnicoMostrarEncuesta: (state) =>
    state.tecnicoInfo?.estadoSolicitud === "EJECUTADO" ||
    state.tecnicoInfo?.estadoSolicitud === "FALLIDO" || state.tecnicoInfo?.estadoSolicitud === "EN_RUTA",
};

const mutations = {
  setTecnicoInfo: (state, data) => {
    state.tecnicoInfo = data;
  },
  setTicket: (state, data) => {
    state.ticket = data;
  },
  setTecnicoEncuesta: (state, data) => {
    state.encuestaEnviada = data;
  },
};

const actions = {
  async consultarTecnicoInfo({ commit }, data) {
    try {
      const resp = await axios.post("/v1/landing-tecnico", data);
      commit("setTecnicoInfo", resp.data.result);
      commit("setTicket", data?.numeroTicket);
      return resp?.data?.result || null;
    } catch (error) {
      commit("setTecnicoInfo", null);
      return error?.response?.data?.errors[0] ?? "No existe información con ese ticket";
    }
  },
  async enviarEncuesta({ commit }, data) {
    try {
      const resp = await axios.post("/v1/landing-tecnico/encuesta", data);
      commit("setTecnicoEncuesta", true);
      return resp;
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
